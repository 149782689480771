<template>
  <b-overlay
    id="resInfo"
    variant="transparent"
    :show="show"
    :opacity="1.00"
    :blur="'2px'"
    rounded="lg"
  >
    <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      shape="square"
      :finish-button-text="$t('dataGeneric.create')"
      :back-button-text="$t('dataGeneric.goBack')"
      :next-button-text="$t('next')"
      class="mb-3"
      @on-complete="formSubmitted"
    >
      <!-- Información del recurso tab -->
      <tab-content
        :title="$t('resources.resourceData')"
        :before-change="validationForm"
      >
        <validation-observer
          ref="accountRules"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                {{ $t("resources.resourceInfoType") }} MÁS INFO
              </h5>
              <small class="text-muted">
                {{ $t("resources.wizardSubtitle") }}
              </small>
            </b-col>

            <b-col
              md="6"
              sm="12"
            >
              <validation-provider
                v-slot="validationContext"
                name="name"
                rules="required|min:3"
                label-for="name"
              >
                <b-form-group :label="$t('message.tableHeader.name') + ' *'">
                  <b-form-input
                    id="name"
                    v-model="name"
                    :state="validationContext.errors.length > 0 ? false : null"
                    :placeholder="$t('resources.resourceName')"
                    maxlength="150"
                  />
                  <small class="text-danger">{{ fix(validationContext) }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              md="6"
              sm="12"
            >
              <validation-provider
                v-slot="{ errors }"
                name="escenarioName"
                rules="required"
              >
                <b-form-group
                  :label="$t('resources.scenarioName') + ' *'"
                  :state="errors.length > 0 ? false : null"
                  :invalid-feedback="$t('required')"
                >
                  <b-form-input
                    id="escenarioName"
                    v-model="escenarioName"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('resources.scenarioName')"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              md="6"
              sm="12"
            >
              <b-form-group :label="$t('dataGeneric.description')">
                <validation-provider
                  v-slot="{ errors }"
                  name="description"
                >
                  <b-form-textarea
                    v-model="comment"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('resources.resourceDescription')"
                    rows="3"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              md="6"
              sm="12"
            >
              <validation-provider
                v-slot="{ errors }"
                name="escenarioTitulo"
                rules="required"
              >
                <b-form-group
                  :label="$t('resources.scenarioTitle') + ' *'"
                  :invalid-feedback="$t('required')"
                >
                  <b-form-input
                    id="escenarioTitulo"
                    v-model="escenarioTitulo"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('resources.scenarioTitle')"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              md="6"
              sm="12"
            >
              <b-form-group :label="$t('resources.scenarioText')">
                <validation-provider
                  v-slot="{ errors }"
                  name="description"
                >
                  <b-form-textarea
                    v-model="textoMasInfo"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('resources.scenarioText')"
                    rows="3"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <image-element-vue
              :image-origin="imageSelected"
              :image-type="'imageSelected'"
              :label-info="$t('infoImage.mInfo')"
              :label="$t('Image')"
              @saveImage="loadImage"
            />
          </b-row>
          <b-row>
            <b-col
              v-if="subgroups"
              md="4"
            >
              <b-form-group
                v-if="hasSubgroups && checkPermissions('users.add_subgroup')"
                :label="$t('Subgroup')"
              >
                <v-select
                  id="subgroup"
                  v-model="subgroup"
                  :options="subgroups"
                  label="name"
                  :placeholder="$t('SinGrupoUsuario')"
                  aria-autocomplete="new-password"
                  autocomplete="new-password"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
    </form-wizard>
  </b-overlay>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  utf8ToB64, messageError, showToast, getSubgroups, checkPermissions,
} from '@/store/functions'
import {
  BFormTextarea,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BOverlay,
} from 'bootstrap-vue'
import { required } from '@validations'
import axios from '@axios'
import { getUserData } from '@/auth/utils'
import ImageElementVue from '@/views/components/ImageElement.vue'
import vSelect from 'vue-select'

export default {
  components: {
    BOverlay,
    ImageElementVue,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BFormTextarea,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
  },
  props: {
    defaultLanguage: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      resourceStatus: false,
      name: '',
      comment: '',
      inputs: null,
      required,
      escenarioName: null,
      escenarioTitulo: null,
      textoMasInfo: null,
      imageSrc: null,
      imageSelected: null,
      imageSelectedPlaceholder: null,
      show: false,
      hasSubgroups: false,
      subgroups: null,
      subgroup: null,
      checkPermissions,
    }
  },
  mounted() {
    const userData = getUserData()
    this.hasSubgroups = userData.profile.client.hasSubgroups
    if (this.hasSubgroups) {
      getSubgroups().then(result => {
        const { edges } = result.data.data.allSubgroups
        this.subgroups = edges.map(e => e.node)
      }).catch(err => {
        console.log(err)
      })
      this.subgroup = userData.profile.subgroup
    }
  },
  methods: {
    loadImage(item) {
      const { data, type } = item

      switch (type) {
        case 'imageSelected':
          this.imageSelected = data
          break

        default:
          break
      }
    },
    fix(data) {
      const existeClave = Object.keys(data.failedRules)
      if (existeClave.includes('required')) {
        return this.$t('required')
      }
      if (existeClave.includes('min')) {
        return this.$t('purchases.nameFormThreeCaract')
      }
      return ''
    },
    chooseImage(imageClicked) {
      this.imageClicked = imageClicked
      document.getElementById(imageClicked).click()
    },
    async formSubmitted() {
      const headers = {
        'Accept-Language': this.defaultLanguage,
        'Content-Language': this.defaultLanguage,
      }
      this.show = true
      const userData = getUserData()
      const data = new FormData()
      const config = {
        timeout: 1000 * 60 * 30,
      }
      // PRIMER PASO: CREAR INSTANCIA MODELO PLAIN
      const queryPlain = `
        mutation( $name: String!, $client: ID!, $title: String, $masInfo: String, $subgroup: ID){
          createPlain(input:{
            name: $name,
            client: $client,
            title: $title,
            text: $masInfo,
            subgroup: $subgroup,
          }){
          plain {
            id
            name
            title
            text
            client {
              id
              name
            }
            subgroup {
              id
              name
            }
          }
        }
        }
      `
      const variablesPlain = {
        name: this.escenarioName,
        client: userData.profile.client.id,
        title: this.escenarioTitulo,
        masInfo: this.textoMasInfo,
        subgroup: this.subgroup?.id,
      }
      data.append('variables', JSON.stringify(variablesPlain))
      data.append('query', queryPlain)
      data.append('image', this.imageSelected)
      let plainId
      await axios.post('', data, { headers }, config).then(res => {
        messageError(res, this)

        plainId = res.data.data.createPlain.plain.id
      })
      // SEGUNDO PASO: CREAR RECURSO MODELO RESOURCE
      const queryResource = `
        mutation($name: String!, $client: ID!, $plainId: ID, $description: String, $subgroup: ID){
          createResource(input:{
            name: $name,
            description: $description
            client: $client,
            type: PLN,
            ${userData.isSuperuser ? '' : `creator:"${utf8ToB64(`id:${userData.id}`)}",`}
            plain: $plainId,
            subgroup: $subgroup,
          }){
          resource {
            id
            name
            type
            description
            plain {
              id
              name
            }
            subgroup {
              id
              name
            }
          }
        }
        }
      `
      const resourceVariables = {
        name: this.name,
        description: this.comment,
        client: userData.profile.client.id,
        plainId,
        subgroup: this.subgroup?.id,
      }
      const dataResource = new FormData()
      dataResource.append('variables', JSON.stringify(resourceVariables))
      dataResource.append('query', queryResource)
      await axios.post('', dataResource, { headers }, config).then(res => {
        messageError(res, this)

        this.show = false
        if (res.status === 200) {
          showToast(this.$t('success'), 1, this)
          this.$router.push('/contents/resources')
        }
      }).catch(() => {
        showToast(this.$t('error'), 2, this)
      })
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
