<template>
  <div id="wizard-resources">
    <b-row class="mb-5">
      <b-col md="10">
        <h1>{{ file }}</h1>
        <h5 class="text-primary">
          {{ fileDetail }}
        </h5>
      </b-col>

      <b-col class="d-flex justify-content-end pt-2 align-items-center">
        <b-img
          v-if="languageService"
          height="20px"
          width="30px"
          :src="languageFlags(defaultLanguage)"
          class="mr-2"
        />
        <router-link to="/contents/resources">
          <b-button
            variant="danger"
            class="text-right"
          >
            {{
              $t("dataGeneric.goBack")
            }}
          </b-button>
        </router-link>
      </b-col>
    </b-row>

    <WizardResMedia
      v-if="type == 'MEDIA'"
      :default-language="defaultLanguage"
    />
    <wizardResBuy
      v-if="type == 'BUY'"
      :default-language="defaultLanguage"
    />
    <WizardResCart
      v-if="type == 'CART'"
      :default-language="defaultLanguage"
    />
    <WizardResDlink
      v-if="type == 'DLINK'"
      :default-language="defaultLanguage"
    />
    <WizardResInfo
      v-if="type == 'INFO'"
      :default-language="defaultLanguage"
    />
    <WizardResTest
      v-if="type == 'TEST'"
      :default-language="defaultLanguage"
    />
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BImg,
} from 'bootstrap-vue'
import languageFlags from '@/@core/utils/languageFlags'

import { getUserData } from '@/auth/utils'
import axios from '@axios'
import wizardResBuy from './components/WizardResBuy.vue'
import WizardResCart from './components/WizardResCart.vue'
import WizardResDlink from './components/WizardResDlink.vue'
import WizardResInfo from './components/WizardResInfo.vue'
import WizardResMedia from './components/WizardResMedia.vue'
import WizardResTest from './components/WizardResTest.vue'

import 'vue-form-wizard/dist/vue-form-wizard.min.css'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BImg,
    wizardResBuy,
    WizardResCart,
    WizardResDlink,
    WizardResInfo,
    WizardResMedia,
    WizardResTest,

  },
  data() {
    return {
      type: this.$route.params.type,
      file: '',
      fileDetail: '',
      defaultLanguage: '',
      userData: getUserData(),
      languageService: false,
    }
  },
  mounted() {
    this.getDefaultLanguage()
    switch (this.type) {
      case 'BUY':
        this.file = this.$t('resources.buy')
        this.fileDetail = this.$t('resDetail.buy')
        break
      case 'CART':
        this.file = this.$t('resources.sendChart')
        this.fileDetail = this.$t('resDetail.cart')
        break
      case 'DLINK':
        this.file = 'DeepLink'
        this.fileDetail = this.$t('resDetail.deep')
        break
      case 'INFO':
        this.file = this.$t('interactivities.info')
        this.fileDetail = this.$t('resDetail.info')
        break
      case 'MEDIA':
        this.file = 'Media'
        this.fileDetail = this.$t('resDetail.media')
        break
      case 'TEST':
        this.file = this.$t('resources.questionnaire')
        this.fileDetail = this.$t('resDetail.test')
        break

      default:
        break
    }
  },
  methods: {
    languageFlags,
    getDefaultLanguage() {
      axios
        .post('', {
          query: `
             query{
             client(id:"${this.userData.profile.client.id}"){
                 defaultLanguage
                 availableLanguages
             }
          }
          `,
        })
        .then(res => {
          const { availableLanguages } = res.data.data.client
          try {
            const arr = availableLanguages.split('|')
            this.languageService = arr.length > 1
          // eslint-disable-next-line no-empty
          } catch (error) {}
          this.defaultLanguage = res.data.data.client.defaultLanguage
        })
        .catch(() => {
        })
    },
  },

}
</script>
