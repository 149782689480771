<template>
  <b-overlay
    id="resMedia"
    variant="transparent"
    :show="show"
    :opacity="1.00"
    :blur="'2px'"
    rounded="lg"
  >
    <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      shape="square"
      :finish-button-text="$t('dataGeneric.create')"
      :back-button-text="$t('dataGeneric.goBack')"
      :next-button-text="$t('next')"
      class="mb-3"
      @on-complete="formSubmitted"
    >
      <!-- Información del recurso tab -->
      <tab-content
        :title="$t('resources.resourceData')"
        :before-change="validationForm"
      >
        <validation-observer
          ref="accountRules"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                {{ $t("resources.resourceInfoType") }} MEDIA
              </h5>
              <small class="text-muted">
                {{ $t("resources.wizardSubtitle") }}
              </small>
            </b-col>

            <b-col
              md="6"
              sm="12"
            >
              <b-form-group :label="$t('message.tableHeader.name') + ' *'">
                <validation-provider
                  v-slot="validationContext"
                  name="name"
                  rules="required|min:3"
                >
                  <b-form-input
                    id="name"
                    v-model="name"
                    :state="validationContext.errors.length > 0 ? false : null"
                    :placeholder="$t('resources.resourceName')"
                    maxlength="150"
                  />
                  <small class="text-danger">{{ fix(validationContext) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group :label="$t('dataGeneric.description')">
                <validation-provider
                  v-slot="{ errors }"
                  name="description"
                >
                  <b-form-textarea
                    v-model="comment"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('resources.resourceDescription')"
                    rows="3"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              v-if="subgroups"
              md="4"
            >
              <b-form-group
                v-if="hasSubgroups && checkPermissions('users.add_subgroup')"
                :label="$t('Subgroup')"
              >
                <v-select
                  id="subgroup"
                  v-model="subgroup"
                  :options="subgroups"
                  label="name"
                  :placeholder="$t('SinGrupoUsuario')"
                  aria-autocomplete="new-password"
                  autocomplete="new-password"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- Configuracion del recurso tab -->
      <tab-content
        :title="$t('confMed')"
        :before-change="validationFormInfo"
      >
        <validation-observer
          ref="infoRules"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                {{ $t("Media") }}
              </h5>
              <small class="text-muted">{{
                $t("resources.configMediaRes")
              }}</small>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-row>
                <b-col cols="6">
                  <b-form-group
                    class="mb-2 mr-1"
                    :label="$t('resources.originMedia')"
                  >
                    <b-form-select
                      v-model="videoResourceType"
                      class="mr-1"
                      :options="videoResourceTypeOption"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  v-if="videoResourceType === 'BUK'"
                  cols="6"
                >
                  <b-form-group
                    class="mb-2 mr-1"
                    :label="$t('Files')"
                  >
                    <div
                      class="base-image-video"
                      @click="chooseImage('fileInputTr')"
                    >
                      <span
                        v-if="!fileResource"
                        class="placeholder"
                      >
                        {{ $t("resources.selectFile") }}
                      </span>
                      <video
                        v-else
                        id="demo"
                        ref="fileInputTrVid"
                        class="
                              video-js
                              vjs-default-skin vjs-big-play-centered
                              base-image-video
                            "
                        data-setup="{&quot;liveui&quot;: true}"
                        controls
                        @loadeddata="videoMaker()"
                        @loadstart="videoMaker()"
                      >
                        <source
                          v-if="trailerCategorySrc"
                          :src="trailerCategorySrc"
                          :type="trailerCategorySrc.includes('mp4')
                            ? 'video/mp4'
                            : 'application/x-mpegURL'
                          "
                        >
                      </video>
                      <b-form-file
                        id="fileInputTr"
                        v-model="fileResource"
                        accept="video/mp4,audio/mp3"
                        class="mr-1"
                        style="display: none"
                      />
                    </div>
                  </b-form-group>
                </b-col>
                <b-col
                  v-else-if="videoResourceType === 'DIR'"
                  cols="6"
                >
                  <validation-provider
                    v-slot="validationContext"
                    name="directoVideo"
                    rules="required|url"
                  >
                    <b-form-group :label="$t('dataGeneric.direct') + ' URL *'">
                      <b-form-input
                        id="directoVideo"
                        v-model="urlFile"
                        autocomplete="new-password"
                        :state="validationContext.errors.length > 0 ? false : null"
                        :placeholder="$t('dataGeneric.direct') + ' URL'"
                        required
                      />
                      <small class="text-danger">{{ confirmRules(validationContext) }}</small>
                    </b-form-group>
                  </validation-provider>
                  <b-form-group :label="$t('usuarioStream')">
                    <b-form-input
                      id="userStream"
                      :value="userStream"
                      autocomplete="new-password"
                      :placeholder="$t('usuarioStream')"
                    />
                  </b-form-group>
                  <b-form-group :label="$t('inputStream')">
                    <b-form-input
                      id="input"
                      v-model="inputStream"
                      autocomplete="new-password"
                      :placeholder="$t('inputStream')"
                    />
                  </b-form-group>
                  <b-form-group :label="$t('passStream')">
                    <b-form-input
                      id="passStream"
                      v-model="passStream"
                      autocomplete="new-password"
                      type="password"
                      :placeholder="$t('passStream')"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  v-else-if="videoResourceType === 'AUD'"
                  cols="6"
                >
                  <b-form-group :label="$t('dataGeneric.direct') + ' Audio *'">
                    <validation-provider
                      v-slot="validationContext"
                      name="direcAudio"
                      rules="required|url"
                    >
                      <b-form-input
                        id="direcAudio"
                        v-model="urlFile"
                        autocomplete="new-password"
                        required
                        :state="validationContext.errors.length > 0 ? false : null"
                        :placeholder="$t('dataGeneric.direct') + ' Audio'"
                      />
                      <small class="text-danger">{{ confirmRules(validationContext) }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group :label="$t('usuarioStream')">
                    <b-form-input
                      id="userStream"
                      v-model="userStream"
                      autocomplete="new-password"
                      :placeholder="$t('usuarioStream')"
                    />
                    <!--  </validation-provider> -->
                  </b-form-group>
                  <b-form-group :label="$t('inputStream')">
                    <b-form-input
                      id="input"
                      v-model="inputStream"
                      autocomplete="new-password"
                      :placeholder="$t('inputStream')"
                    />
                  </b-form-group>
                  <b-form-group :label="$t('passStream')">
                    <b-form-input
                      id="passStream"
                      v-model="passStream"
                      type="password"
                      autocomplete="new-password"
                      :placeholder="$t('passStream')"
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  v-else-if="videoResourceType === 'URL' || videoResourceType === 'PRE'"
                  cols="6"
                >
                  <b-form-group
                    :key="videoResourceType"
                    :label="$t('resources.fileUrl') + ' *'"
                  >
                    <validation-provider
                      v-slot="validationContext"
                      name="urlFile"
                      rules="url|required"
                    >
                      <b-form-input
                        id="urlFile"
                        v-model="urlFile"
                        required
                        autocomplete="new-password"
                        :placeholder="$t('resources.fileUrl')"
                        :state="validationContext.errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ confirmRules(validationContext) }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col
                  v-else-if="videoResourceType === 'BRU'"
                  cols="6"
                >
                  <b-form-group
                    :key="videoResourceType"
                    :label="$t('workId') + ' *'"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="obra"
                      rules="required"
                    >
                      <b-form-input
                        id="obra"
                        v-model="urlFile"
                        required
                        autocomplete="new-password"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('workId')"
                      />
                      <small class="text-danger">{{ brightcove(errors) }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  v-else-if="videoResourceType === 'AUR'"
                  cols="6"
                >
                  <b-form-group :label="$t('resources.audioUrl') + ' *'">
                    <validation-provider
                      v-slot="validationContext"
                      name="urlAudio"
                      rules="required|url"
                    >
                      <b-form-input
                        id="urlAudio"
                        v-model="urlFile"
                        :state="validationContext.errors.length > 0 ? false : null"
                        :placeholder="$t('resources.audioUrl')"
                      />
                      <small class="text-danger">{{ confirmRules(validationContext) }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="videoResourceType !== 'AUR' && videoResourceType != 'BRU'
                  "
                  class="mb-2"
                  cols="6"
                >
                  <b-embed
                    v-if="urlFile"
                    type="video"
                    aspect="16by9"
                    :src="urlFile"
                    allowfullscreen
                    controls
                  />
                  <b-embed
                    v-else-if="trailerCategorySrc"
                    type="video"
                    aspect="16by9"
                    :src="trailerCategorySrc"
                    allowfullscreen
                    controls
                  />
                </b-col>
                <b-col
                  v-else
                  cols="6"
                >
                  <audio
                    v-if="urlFile && videoResourceType != 'BRU'"
                    controls
                  >
                    <source
                      :src="urlFile"
                      type="audio/mpeg"
                    >
                  </audio>
                </b-col>
                <b-col
                  v-if="videoResourceType == 'URL' ||
                    videoResourceType == 'DIR'
                    || videoResourceType == 'PRE'"
                  cols="6"
                >
                  <b-form-group label="URL thumbnail track">
                    <validation-provider
                      v-slot="validationContext"
                      name="thumbnailTrackUrl"
                      rules="url"
                    >
                      <b-form-input
                        id="thumbnailTrackUrl"
                        v-model="thumbnailTrackUrl"
                        required
                        autocomplete="new-password"
                        placeholder="Thumbnail track"
                        :state="validationContext.errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ confirmRules(validationContext) }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
    </form-wizard>
  </b-overlay>
</template>

<script>
import 'videojs-markers'
import videojs from 'video.js'
import 'videojs-markers/dist/videojs.markers.css'
import 'videojs-markers/dist/videojs.markers.min.css'
import 'video.js/dist/video-js.css'
import 'video.js/dist/video.min'
import 'video.js/dist/video'
import 'videojs-markers/dist/videojs-markers.min'
// eslint-disable-next-line import/no-extraneous-dependencies
import '@videojs/http-streaming/dist/videojs-http-streaming.min'
// eslint-disable-next-line import/no-extraneous-dependencies
import '@videojs/http-streaming/dist/videojs-http-streaming'
import {
  utf8ToB64, showToast, messageError, base64Encode, getSubgroups, checkPermissions,
} from '@/store/functions'
import { FormWizard, TabContent } from 'vue-form-wizard'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BFormSelect,
  BFormTextarea,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BEmbed,
  BOverlay,
} from 'bootstrap-vue'
import { required } from '@validations'
import axios from '@axios'

// import { codeIcon } from './code'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BEmbed,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BFormSelect,
    BFormTextarea,
    BFormFile,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BOverlay,
    vSelect,
  },
  props: {
    defaultLanguage: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      videoResourceType: 'DIR',
      videoResourceTypeOption: [
        // { value: "BUK", text: "Bucket" },
        { value: 'BRU', text: this.$t('workId') },
        { value: 'URL', text: this.$t('fileVid') },
        { value: 'AUR', text: this.$t('fileAud') },
        { value: 'DIR', text: this.$t('liveVid') },
        { value: 'AUD', text: this.$t('liveAud') },
        { value: 'PRE', text: this.$t('prevVid') },
      ],
      tipo: '',
      name: '',
      thumbnailTrackUrl: '',
      comment: '',
      inputs: null,
      required,
      trailerCategoryPlaceholder: '',
      trailerCategorySrc: null,
      fileResource: null,
      trailerCategorySelected: null,
      directoUrl: '',
      urlFile: '',
      userStream: null,
      passStream: null,
      inputStream: null,
      audioUrl: '',
      video: null,
      show: false,
      uploadFilesList: [],
      idFileResource: null,
      hasSubgroups: false,
      subgroups: null,
      subgroup: null,
      checkPermissions,
    }
  },
  watch: {
    fileResource(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then(value => {
              this.trailerCategorySrc = value
            })
            .catch(() => {
              this.trailerCategorySrc = null
            })
        } else {
          this.trailerCategorySrc = null
        }
      }
    },
  },
  mounted() {
    const userData = getUserData()
    this.hasSubgroups = userData.profile.client.hasSubgroups
    if (this.hasSubgroups) {
      getSubgroups().then(result => {
        const { edges } = result.data.data.allSubgroups
        this.subgroups = edges.map(e => e.node)
      }).catch(err => {
        console.log(err)
      })
      this.subgroup = userData.profile.subgroup
    }
  },

  beforeDestroy() {
    if (this.video) this.video.dispose()
  },
  methods: {
    fix(data) {
      const existeClave = Object.keys(data.failedRules)
      if (existeClave.includes('required')) {
        return this.$t('purchases.nameFormValid')
      }
      if (existeClave.includes('min')) {
        return this.$t('purchases.nameFormThreeCaract')
      }
      return ''
    },
    confirmRules(data) {
      const existeClave = Object.keys(data.failedRules)
      if (existeClave.includes('required')) {
        return this.$t('tabUser.urlRequired')
      }
      if (existeClave.includes('url')) {
        return this.$t('tabUser.urlValid')
      }
      return ''
    },
    brightcove(data) {
      if (data.length !== 0) {
        return this.$t('IdBrightcove')
      }
      return ''
    },

    chooseImage(imageClicked) {
      this.imageClicked = imageClicked
      document.getElementById(imageClicked).click()
    },
    async formSubmitted() {
      const headers = {
        'Accept-Language': this.defaultLanguage,
        'Content-Language': this.defaultLanguage,
      }
      this.show = true
      const userData = getUserData()

      const data = new FormData()
      const config = {
        timeout: 1000 * 60 * 30,
      }
      const variables = {
        name: this.name,
        description: this.comment,
        client: userData.profile.client.id,
        videotype: this.videoResourceType,
        inputStream: this.inputStream,
        userStream: this.userStream,
        passwordStream: this.passStream,
        thumbnailTrackUrl: this.thumbnailTrackUrl,
        subgroup: this.subgroup?.id,
      }
      const query = `
          mutation( $name: String!,$thumbnailTrackUrl: String, $description: String, $client: ID!, $directUrl: String, $inputStream: String, $userStream: String, $passwordStream: String, $videotype: ResourceVideotypeInput, $subgroup: ID){
            createResource(input:{
              name: $name,
              client: $client,
              description: $description,
              type: VID,
              videotype: $videotype,
              thumbnailTrackUrl: $thumbnailTrackUrl,
              directUrl: $directUrl,
              inputStream: $inputStream,
              ${userData.isSuperuser ? '' : `creator:"${utf8ToB64(`id:${userData.id}`)}",`}
              userStream: $userStream,
              passwordStream: $passwordStream,
              subgroup: $subgroup,
            }){
            resource {
              id
              name
              type
              description
              subgroup {
                id
                name
              }
            }
          }
          }
        `
      variables.directUrl = this.urlFile

      /* COMÚN */
      data.append('variables', JSON.stringify(variables))
      data.append('query', query)

      axios.post('', data, { headers }, config).then(res => {
        messageError(res, this)
        this.show = false

        if (res.status === 200) {
          showToast(this.$t('success'), 1, this)
          this.$router.push('/contents/resources')
        }
      }).catch(() => {
        showToast(this.$t('error'), 2, this)
      })
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    videoMaker() {
      this.video = videojs('demo')

      this.video.on('ready', function () {
        try {
          this.video.vhs = null
        // eslint-disable-next-line no-empty
        } catch (error) { }
      })
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";

#resMedia .base-image-video {
  display: block;
  width: 200px;
  height: 200px;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}

#resMedia .placeholder {
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}

#resMedia .placeholder:hover {
  background: #e0e0e0;
}

#resMedia .file-input {
  display: none;
}
</style>
