<template>
  <b-overlay
    variant="transparent"
    :show="show"
    :opacity="1.00"
    :blur="'2px'"
    rounded="lg"
  >
    <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      shape="square"
      :finish-button-text="$t('dataGeneric.create')"
      :back-button-text="$t('dataGeneric.goBack')"
      :next-button-text="$t('next')"
      class="mb-3"
      @on-complete="formSubmitted"
    >
      <!-- Información del recurso tab -->
      <tab-content
        :title="$t('resources.resourceData')"
        :before-change="validationForm"
      >
        <validation-observer
          ref="accountRules"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                {{ $t("resources.resourceInfoType") }} BUY
              </h5>
              <small class="text-muted">
                {{ $t("resources.wizardSubtitle") }}
              </small>
            </b-col>

            <b-col
              md="6"
              sm="12"
            >
              <b-form-group :label="$t('message.tableHeader.name') + ' *'">
                <validation-provider
                  v-slot="validationContext"
                  name="name"
                  rules="required|min:3"
                >
                  <b-form-input
                    id="name"
                    v-model="name"
                    :state="validationContext.errors.length > 0 ? false : null"
                    :placeholder="$t('resources.resourceName')"
                    maxlength="150"
                  />
                  <small class="text-danger">{{ fix(validationContext) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              md="6"
              sm="12"
            >
              <validation-provider
                v-slot="{ errors }"
                name="product"
                rules="required"
              >
                <b-form-group
                  :label="$t('producto') + ' *'"
                  :invalid-feedback="$t('required')"
                  :state="errors.length > 0 ? false : null"
                >
                  <div class="d-flex">
                    <b-form-input
                      id="product"
                      v-model="productName"
                      disabled
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('producto')"
                    />
                    <b-button
                      variant="primary"
                      class="ml-2"
                      @click="$refs['product-modal'].show()"
                    >
                      {{ $t('select') }}
                    </b-button>
                  </div>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="6">
              <b-form-group :label="$t('dataGeneric.description')">
                <validation-provider
                  v-slot="{ errors }"
                  name="description"
                >
                  <b-form-textarea
                    v-model="comment"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('resources.resourceDescription')"
                    rows="3"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="typeShop !== 'INT'"
              md="6"
              sm="12"
            >
              <validation-provider
                v-slot="{ errors }"
                name="product-reference"
                rules="required"
              >
                <b-form-group
                  :invalid-feedback="$t('required')"
                  label-for="product-reference"
                >
                  <span>
                    {{ $t('resources.productReference') + ' *' }}
                  </span>
                  <b-form-input
                    id="product-reference"
                    v-model="productReference"
                    class="mt-50"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('resources.productReference')"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              v-if="subgroups"
              md="4"
            >
              <b-form-group
                v-if="hasSubgroups && checkPermissions('users.add_subgroup')"
                :label="$t('Subgroup')"
              >
                <v-select
                  id="subgroup"
                  v-model="subgroup"
                  :options="subgroups"
                  label="name"
                  :placeholder="$t('SinGrupoUsuario')"
                  aria-autocomplete="new-password"
                  autocomplete="new-password"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
    </form-wizard>
    <b-modal
      id="product-modal"
      ref="product-modal"
      hide-footer
      size="xl"
      :title="$t('code.sel')"
    >
      <div class="d-block text-center">
        <product-selector
          :columns="[
            { key: 'image', label: $t('purchases.category') },
            { key: 'name', label: $t('dataGeneric.name') },
            { key: 'vendor', label: $t('Vendedor') },
          ]"
          @confirm-selection="SelectProduct"
        />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="$refs['product-platform'].hide()"
      >
        {{ $t("dataGeneric.close")
        }}
      </b-button>
    </b-modal>
  </b-overlay>
</template>

<script>

import { FormWizard, TabContent } from 'vue-form-wizard'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  showToast, utf8ToB64, messageError, getSubgroups, checkPermissions,
} from '@/store/functions'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { getUserData } from '@/auth/utils'
import {
  BFormTextarea,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BOverlay,
  BModal,
} from 'bootstrap-vue'
import { required } from '@validations'
import axios from '@axios'
import ProductSelector from '@/views/common/ProductSelector.vue'
import vSelect from 'vue-select'

export default {
  components: {
    BOverlay,
    BButton,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BModal,
    BFormTextarea,
    BRow,
    BCol,
    ProductSelector,
    BFormGroup,
    BFormInput,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
  },
  props: {
    defaultLanguage: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      name: '',
      comment: null,
      userData: getUserData(),
      inputs: null,
      required,
      productId: null,
      productReference: null,
      typeShop: null,
      show: false,
      productName: null,
      hasSubgroups: false,
      subgroups: null,
      subgroup: null,
      checkPermissions,
    }
  },
  mounted() {
    this.getClient()

    const userData = getUserData()
    this.hasSubgroups = userData.profile.client.hasSubgroups
    if (this.hasSubgroups) {
      getSubgroups().then(result => {
        const { edges } = result.data.data.allSubgroups
        this.subgroups = edges.map(e => e.node)
      }).catch(err => {
        console.log(err)
      })
      this.subgroup = userData.profile.subgroup
    }
  },
  methods: {
    SelectProduct(data) {
      this.productId = data.node.id
      this.productName = data.node.name
      this.productReference = data.node.reference
      this.$refs['product-modal'].hide()
      if (!this.productReference && this.typeShop !== 'INT') this.selectedProductWithoutReference()
    },
    getClient() {
      axios
        .post('', {
          query: `query{
            allClients(clientId:"${this.userData.profile.client.clientId}"){
              edges {
                node {
                  id
                  typeShop                  
                }
              }
            }
          }
`,
        })
        .then(result => {
          messageError(result, this)
          const response = result.data.data.allClients.edges[0]
          this.typeShop = response.node.typeShop
        })
        .catch(err => {
          console.log(err)
        })
    },
    fix(data) {
      const existeClave = Object.keys(data.failedRules)
      if (existeClave.includes('required')) {
        return this.$t('required')
      }
      if (existeClave.includes('min')) {
        return this.$t('purchases.nameFormThreeCaract')
      }
      return ''
    },
    formSubmitted() {
      const headers = {
        'Accept-Language': this.defaultLanguage,
        'Content-Language': this.defaultLanguage,
      }
      this.show = true
      const data = new FormData()
      const config = {
        timeout: 1000 * 60 * 30,
      }
      const queryResource = `
        mutation($name: String!, $client: ID!, $productID: ID,
          ${this.typeShop !== 'INT' ? '$productReference: String,' : ''}
          $description: String,
          $subgroup: ID){
          createResource( input:{
            name: $name,
            description: $description
            client: $client,
            type: ${this.typeShop === 'INT' ? 'BUY' : 'A2B'},
            ${this.userData.isSuperuser ? '' : `creator:"${utf8ToB64(`id:${this.userData.id}`)}",`}
            product: $productID,
            ${this.typeShop !== 'INT' ? 'remoteProductId: $productReference' : ''},
            subgroup: $subgroup,
          }){
          resource {
            id
            name
            type
            description
            product{
              id
            }
            remoteProductId
            subgroup {
                id
                name
              }
          }
        }
        }
      `
      const resourceVariables = {
        name: this.name,
        description: this.comment,
        client: this.userData.profile.client.id,
        productID: this.productId,
        productReference: this.typeShop !== 'INT' ? this.productReference : '',
        subgroup: this.subgroup?.id,
      }
      data.append('variables', JSON.stringify(resourceVariables))
      console.log(resourceVariables)
      data.append('query', queryResource)

      axios.post('', data, { headers }, config).then(res => {
        messageError(res, this)

        this.show = false

        if (res.status === 200) {
          showToast(this.$t('success'), 1, this)
          this.$router.push('/contents/resources')
        }
      }).catch(() => {
        showToast(this.$t('error'), 2, this)
      })
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    selectedProductWithoutReference() {
      this.$swal({
        title: this.$t('warning'),
        text: this.$t('noExternalReferenceFound'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('yNext'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (!result.isConfirmed) {
            this.productName = null
            this.productId = null
            this.productReference = null
          } else { document.getElementById('product-reference').focus() }
        })
        .catch(() => { })
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
